import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/en"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import TextSection from "../components/ui-sections/text-section"
import ImageGallery from "../components/ui-sections/image-gallery"

const EspaisPage = (props) => {
	const heroText = "Spaces"
	const textSectionText = "Our rooms are spacious and set in our land, the Alt Penedès. We have private rooms for special group events, both family celebrations or with friends, as well as company events. Our winery has a beautiful table with capacity for 12 people to enjoy, among more than 300 wine references, a tasting menu that Pere will prepare tailor-made for you. We also have a digestive room for after lunch. We don’t want you to have any rush. We want you to feel right at home."
	return (
	  <Layout location={props.location}>
	    <SEO 
	    	title="Spaces"
	    	lang="en"
	    	description={textSectionText}
	    />
	    <HeroImage 
	    	titleText={heroText}
	    	image="espais" 
	    />
	    <TextSection textSectionText={textSectionText} />
	    <StaticQuery
	      query={graphql`
	        # Replace this comment with the GraphQL query above
	        {
	          allEspaisImagesEnYaml {
	            edges {
	              node {
	                title
	                image {
	                	id
	                  childImageSharp {
	                    fluid {
	                      aspectRatio
	                      originalImg
	                      # Use gatsby-image later for better user experience
	                      ...GatsbyImageSharpFluid
	        }}}}}}}
	      `}
	      render={data => (
	        <ImageGallery
	          itemsPerRow={[2, 5]} // This will be changed to `[2, 3]` later
	          images={data.allEspaisImagesEnYaml.edges.map(({ node }) => ({
	          	id: node.image.id,
	            ...node.image.childImageSharp.fluid,
	            caption: `${node.title}`,
	          }))}
	        />
	      )}
	    />
	  </Layout>
	)
}

export default EspaisPage
